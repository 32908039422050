import { request } from '@/utils/axios'

/**
 * 查询综合情况
 * @param parameter
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function queryOverallSituation(parameter) {
  return new Promise(resolve => {
    request({
      url: '/api/v1/dms/certificate/queryOverallSituation',
      method: 'get',
      params: parameter,
      verifyToken: true
    }).then(res => {
      if (res.success && res.result) {
        resolve(res)
      }
    })
  })
}


/**
 * 查询最新认证
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function queryRecentlyCertification() {
  return new Promise((resolve, reject) => {
    request({
      url: `/api/v1/dms/certificate/queryRecentlyCertification`,
      method: 'get',
      verifyToken: true
    }).then(res => {
      if (res.success && res.result) {
        resolve(res)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 * 查询城市获证数量
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function queryCityCertificateNumber() {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/v1/dms/certificate/queryCityCertificateNumber',
      method: 'get',
      verifyToken: true
    }).then(res => {
      if (res.success && res.result) {
        resolve(res)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 * 统计认证产品类别数量
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function countCertificationProductCategoryNumber() {
  return new Promise(resolve => {
    request({
      url: '/api/v1/dms/certificate/countCertificationProductCategoryNumber',
      method: 'get',
      verifyToken: true
    }).then(res => {
      if (res.success && res.result) {
        resolve(res)
      }
    })
  })
}


/**
 * 统计证书标色数量
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function countCertificateIconColorNumber() {
  return new Promise(resolve => {
    request({
      url: '/api/v1/dms/certificate/countCertificateIconColorNumber',
      method: 'get',
      verifyToken: true
    }).then(res => {
      if (res.success && res.result) {
        resolve(res)
      }
    })
  })
}

/**
 * 统计全国分布情况
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function countNationalDistributionSituation() {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/v1/dms/certificate/countNationalDistributionSituation',
      method: 'get',
      verifyToken: true
    }).then(res => {
      if (res.success && res.result) {
        resolve(res)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}
