<!--
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
-->
<template>
  <div style="height: 100%;">
    <p-dv-border-box10 title="最新认证" class="header-analyse box">
      <div class="box-content">
        <div class="loading-box" v-if="dataLoading">
          <img src="~@assets/loading.gif" alt="">
        </div>
        <dv-scroll-board v-else-if="tableData.data.length > 0 && !dataLoading" :config="tableData" @click="tableClick"/>
        <!--    新增插槽属性，预留出hover气泡功能-->
        <!--        暂无数据-->
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
    </p-dv-border-box10>
    <p-dv-border-box10 title="各城市获证数量TOP3" class="header-question box">
      <div class="loading-box" v-if="dataLoading">
        <img src="~@assets/loading.gif" alt="">
      </div>
      <DvScrollRankingBoard
        :config="config"
        style="width:90%;height:75%"
        class="dv-scroll"
        v-else-if="config.data.length > 0 && !dataLoading"
        @clickItem="clickItem"/>
      <!--        暂无数据-->
      <el-empty description="暂无数据" v-else></el-empty>
    </p-dv-border-box10>
    <!--    <p-dv-border-box10 title="企业获证产品排行" class="header-question">-->
    <!--      <div class="box-content">-->
    <!--        &lt;!&ndash;        <dv-scroll-ranking-board&ndash;&gt;-->
    <!--        &lt;!&ndash;          :config="foodList"&ndash;&gt;-->
    <!--        &lt;!&ndash;          style="width:95%;height:30vh;"&ndash;&gt;-->
    <!--        &lt;!&ndash;          class="dv-scroll"&ndash;&gt;-->
    <!--        &lt;!&ndash;          v-if="foodList.data.length > 0"/>&ndash;&gt;-->
    <!--        <dv-scroll-board :config="rankData" @mouseover="tableMouseover"/>-->
    <!--        &lt;!&ndash;    新增插槽属性，预留出hover气泡功能&ndash;&gt;-->
    <!--        <slot></slot>-->
    <!--      </div>-->
    <!--    </p-dv-border-box10>-->
  </div>
</template>
<script>

  import PDvBorderBox10 from '@comp/datav/border/PDvBorderBox10'
  import DvScrollRankingBoard from './modules/DvScrollRankingBoard'
  import { queryCityCertificateNumber, queryRecentlyCertification } from '../api/requestBigScreen'

  export default {
    name: 'HomeLeft',
    components: { PDvBorderBox10, DvScrollRankingBoard },
    data() {
      return {
        dataLoading: false,
        tableData: {
          header: ['产品名称', '获证企业', '获证数量'],
          align: 'center',
          data: [
            ['“泉水叮咚” 瓶装饮用天然泉水：238mL、330mL、333mL、350mL', '广西泉水叮咚饮品有限公司', '1个', '1907302055170134018'],
            ['金奖豆豉鲮鱼罐头、鲜炸鲮鱼罐头', '广州鹰金钱食品集团有限公司', '1个', '1907462451088592898'],
            ['“九千万”、“九千万山泉”牌饮用天然泉水：350mL/瓶、339mL/瓶、500mL/', '广西中源山泉有限公司', '1个', '1907302059330883585'],
            ['融安金桔', '广西融安蚂蚁农业发展有限公司', '1个', '1904794692484161537'],
            ['冷冻鱼糜制品(达濠鱼丸);其他水产制品（达濠鱼丸）', '汕头市晶华食品有限公司', '1个', '1899845880300802050'],
            ['竹笋罐头', '揭阳市青源农业种养基地有限公司', '1个', '1897954596061446145'],
            ['LED控制装置', '广东擎洲光电科技股份有限公司', '1个', '1897954598141820929'],
            ['全脂羊奶粉', '西安安诺乳业有限公司', '1个', '1892531715152359425'],
            ['梅州金柚', '梅州市兴缘农业发展有限公司', '1个', '1893892803655729154'],
            ['南美白对虾', '广东画景食品有限公司', '1个', '1876480873066962946'],
            ['白酒', '广东顺德酒厂有限公司', '1个', '1873029825334648834'],
            ['蒸压加气混凝土砌块', '杭加（广东）建筑节能新材料有限公司', '1个', '1873010840232898561'],
            ['奶白菜', '山东艾绿吉泰农业科技有限公司', '1个', '1876480869552136193'],
            ['阳光玫瑰葡萄', '广隆农业经济技术发展有限公司', '1个', '1861784617845571585'],
            ['虎瞰金针菜（鲜）', '汕尾市绿舜生态农业有限公司', '1个', '1861784621616250881'],
            ['生生珍品鲩', '生生农业集团股份有限公司', '1个', '1861784624933945346'],
            ['新岗红茶', '怀集高山青农产品有限公司', '1个', '1861784639517540354'],
            ['六堡茶', '梧州市天誉茶业有限公司', '1个', '1839917061576351746'],
            ['饮用水', '广西忆然饮用水有限公司', '1个', '1839919953234386945'],
            ['净水器', '广东栗子科技有限公司‌', '1个', '1861784642701017090'],
            ['凯度牌嵌入式净饮机', '深圳市凯度电器有限公司', '1个', '1861784651290951681'],
            ['六堡茶', '苍梧六堡茶业有限公司', '1个', '1839908097597530113']
          ]
        },
        value: '',
        config: {
          unit: '个',
          data: [
            {
              name: '佛山市',
              value: 25,
              ranking: 1
            },
            {
              name: '广州市',
              value: 23,
              ranking: 2
            },
            {
              name: '深圳市',
              value: 7,
              ranking: 3
            },
            {
              name: '肇庆市',
              value: 6
            },
            {
              name: '清远市',
              value: 6
            },
            {
              name: '惠州市',
              value: 5
            },
            {
              name: '珠海市',
              value: 4
            },
            {
              name: '江门市',
              value: 4
            },
            {
              name: '云浮市',
              value: 4
            },
            {
              name: '韶关市',
              value: 3
            },
            {
              name: '中山市',
              value: 4
            },
            {
              name: '茂名市',
              value: 2
            },
            {
              name: '汕尾市',
              value: 2
            },
            {
              name: '河源市',
              value: 2
            },
            {
              name: '东莞市',
              value: 3
            },
            {
              name: '揭阳市',
              value: 2
            },
            {
              name: '湛江市',
              value: 2
            },
            {
              name: '梧州市',
              value: 2
            },
            {
              name: '青岛市',
              value: 2
            },
            {
              name: '北京市',
              value: 1
            },
            {
              name: '潮州市',
              value: 1
            },
            {
              name: '济南市',
              value: 1
            },
            {
              name: '贺州市',
              value: 1
            },
            {
              name: '柳州市',
              value: 2
            },
            {
              name: '南京市',
              value: 1
            },
            {
              name: '桂林市',
              value: 1
            },
            {
              name: '南宁市',
              value: 1
            },
            {
              name: '西安市',
              value: 1
            },
            {
              name: '汕头市',
              value: 1
            },
            {
              name: '河池市',
              value: 2
            },
            {
              name: '梅州市',
              value: 1
            }
          ]
        }
      }
    },
    mounted() {
    },
    created() {
      // TODO:交互后开放上线
      // this.initData()
    },
    methods: {
      initData() {
        this.dataLoading = true
        Promise.all([queryCityCertificateNumber().then((res) => {
          let sortData = res.result.sort((a, b) => Number(b.certificateNumber) - Number(a.certificateNumber))
          // 过滤掉为0的数据
          sortData = sortData.filter(item => Number(item.certificateNumber) > 0 && item.cityName.includes('市'))
          this.config.data = sortData.map((item, index) => {
            return {
              name: item.cityName,
              value: Number(item.certificateNumber),
              ranking: index < 3 ? index + 1 : undefined
            }
          })
        }), queryRecentlyCertification().then((res) => {
          this.tableData.data = res.result.map(item => [
            item.productName,
            item.companyName,
            '1个',
            item.certificateId
          ]
          )
        })]).then(() => {
          this.dataLoading = false
        }).finally(() => {
          this.dataLoading = false
        })
      },
      clickItem(item) {
        this.$emit('clickItem', item.name)
      },
      tableClick(row) {
        console.log(row)
        this.$router.push({
          path: '/bayareacertification/queryDetail',
          query: {
            id: row.row[3]
          }
        })
      },
      onResize({ height, el }) {
        // 根据尺寸内部等比缩放
        let p1 = 438 / 316
        let p2 = el.offsetWidth / el.offsetHeight
        let percent = p1 / p2
        el.style.transform = `scale(${percent})`
      }
    }
  }
</script>

<style lang="less" scoped>
@import '~@/assets/styles/home.less';

.box {
  width: 100%;
  margin-bottom: 20px;
}

@date-height: 70px;
@date-margin-bottom: 18px;
.header-date {
  margin-bottom: @date-margin-bottom;
  height: @date-height;
}

//@height: calc((100% - @date-height - @date-margin-bottom - 2 * @margin-bottom) / 2);
@height: 48%;
.header-analyse {
  background: url("~@/assets/bigscreen/bor1_1.png") no-repeat;
  background-size: 100% 100%;
  height: @height;
}

.header-question {
  background: url("~@/assets/bigscreen/bor1_1.png") no-repeat;
  background-size: 100% 100%;
  height: @height;

  &-select {
    width: 150px;

    /deep/ .el-input__inner {
      height: 30px;
      background: #115987;
      color: #63AACE;
      font-weight: bold;
      border: 1px solid transparent;

    }

    /deep/ .el-input__suffix-inner {
      position: relative;
      top: -2px;
    }
  }
}

.box-content {
  height: calc(100% - 50px);
}

@background: linear-gradient(180deg, rgba(18, 186, 255, 1) 0%, rgba(148, 236, 255, 1) 100%);
/deep/ .ranking-column {
  border-bottom: 1px solid rgb(125, 226, 255) !important;

  .inside-column {
    background: @background !important;
  }
}

/deep/ .rank {
  color: rgb(125, 226, 255) !important;
}

.dv-scroll {
  margin: 0 auto;
}

/deep/ .dv-scroll-ranking-board .ranking-column .inside-column {
  background-color: unset;
  background: linear-gradient(to right, #00C1FB, #12FFF7) !important;
}

/deep/ .dv-scroll-ranking-board .ranking-column {
  border-bottom: 2px solid rgba(19, 112, 251, 0.5);
  margin-top: 0 !important;
}

/deep/ .dv-scroll-ranking-board .ranking-info .rank {
  color: #00C1FB;
}

/deep/ .dv-scroll-ranking-board .ranking-info {
  font-size: 14px;
  font-weight: 700;
}

/deep/ .dv-scroll-ranking-board .ranking-info .rank {
  width: 2.875rem;
}

/deep/ .dv-scroll-ranking-board .ranking-info .info-name {
  flex: 1;
  text-align: left;
}

/deep/ .dv-scroll-ranking-board {
  overflow-y: scroll;
  overflow-x: hidden;
}

// 滚动条样式
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/deep/ .dv-scroll-board {
  height: 100%;

  .header {
    display: flex;
    justify-content: space-around;
    background-color: transparent !important;
    color: #46BDF6;
    font-weight: bold;
    border-bottom: 2px solid #46BDF6;

    .header-item {
      width: 120px;
      height: 25px !important;
      line-height: 20px !important;
    }
  }

  .rows {
    .row-item {
      display: flex;
      justify-content: space-around;
      background-color: transparent !important;
      cursor: pointer;

      .ceil {
        width: 120px !important;

        .index {
          background-color: transparent !important;
        }

        &:nth-child(4) {
          display: none !important;
        }
      }
    }
  }
}

.loading-box {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-empty__image {
  width: 5rem;
}

/deep/ .el-empty {
  height: 100%;
}
</style>
